import React from "react"
import { connect } from "react-redux"
import Vimeo from "../images/vimeo.png"
import Fb from "../images/fb.png"
import Ig from "../images/ig.png"
import HeaderDecider from "../components/headerDecider"
import pic1 from "../images/mob/contact/contact1.jpg"

const contact = `https://player.vimeo.com/external/363864617.hd.mp4?s=e31b1cdba03335906d953ea6b1870539ed305e18&profile_id=175`

const ContactPage = (props) => {

  let vidStyle = {
    flex: `none`
  }

  let vidCont = {

    display: `flex`,
    justifyContent: `center`,
    height: props.height,
    overflow: `hidden`,

  }

  if (props.width > 1240) {

    vidCont = {
      width: props.width
    }

    vidStyle = {
      width: props.width
    }

  }

  let contactMedia;

  if (props.width < 768) {

    const picStyle = {
      width: props.width,
      height: `100%`,
      position: `fixed`,
      overflow: `hidden`
    }

    const picCont = {
      position: `relative`,
    }

    contactMedia = 
      <div style={picCont}>
        <img src={pic1} alt={pic1} style={picStyle} />
      </div>

  } else {

    contactMedia = 
    <div style={vidCont}>
      <video rel="preconnect" style={vidStyle} muted={props.mute} src={contact} loop autoPlay />
    </div>

  }
  
  const divStyle = {
    position: `fixed`,
    color: `white`,
    top: `50%`,
    left: `50%`,
    width: `200px`,
    marginLeft: `-100px`,
    fontSize: `12px`,
    zIndex: 2,
    textAlign: `center`
  }

  return [  

    <HeaderDecider />,
    
    <div style={divStyle}>

      hola@elefantelefante.com
      <br />
      <br />

      <div style={{width: `100%`}}>

        <a href="https://www.instagram.com/e.l.e.f.a.n.t.e/?hl=en" target="_blank"><img className="socials" src={Ig} /></a>

        <a href="https://vimeo.com/elefantelefante" target="_blank"><img className="socials" src={Vimeo} /></a>

        <a href="https://www.facebook.com/elefante1984/" target="_blank"><img className="socials" src={Fb} /></a>

      </div>
      
    </div>,

    <div>{contactMedia}</div>

  ]

}

const mapStateToProps = (state) => {
  return {
      width: state.width,
      height: state.height,
      mute: state.mute
  }
}

export default connect(mapStateToProps)(ContactPage)